import TableRow from '@material-ui/core/TableRow';
import { styled } from '@material-ui/core/styles';

const AMITableRow = styled(TableRow)(({ theme }) => ({
    '&': {
        backgroundColor: theme.palette.common.white,

        // '&:hover': {
        //     backgroundColor: '#EDEDED',
        // },
    },
    // hide last border
    // '&:last-child td, &:last-child th': {
    //   border: 0
    // }
}));

export default AMITableRow;
