import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSystemState } from '../../store/system/actions';

export const formatDate = (date: Date, isLong: boolean = false) => {
  return isLong
    ? moment(date).format('LLL')
    : moment(date).format('MMM. DD, YYYY');
};

export const formatHistoryDate = (date: Date) => {
  return moment(date).format('L');
};

export const formatHistoryTime = (date: Date) => {
  return moment(date).format('LT');
};

export const formatNumber = (n: number | string) => {
  return new Intl.NumberFormat('en-US').format(
    typeof n === 'string' ? parseFloat(n) : n,
  );
};

export const toPercentage = (value: number, total: number) => {
  const percent = (value / total) * 100;
  return isNaN(percent) ? '0.00%' : percent.toFixed(2) + '%';
};

export const useClearHeader = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setSystemState({
        headerText: {
          main: '',
          sub: '',
        },
      }),
    );
  }, [dispatch]);
};
