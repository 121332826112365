import React from 'react';
import Box from '@material-ui/core/Box';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
    ChartData,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { 
    getTopFiveReport,
    selectTopFiveState,
    setTopFiveFilter
   } from '../../../store/marketreport/topfive/reducer'
import useAtBreakpoint from '../../symphony/hooks/useAtBreakpoint';
import ChartContainer from './ChartContainer';
import { dataColors } from './util/chart-utils';  
import SingleFilterGroup from './SingleFilterGroup';
import { AppState } from '../../../store';
import { Typography } from '@material-ui/core';
const { blue, red, brown, teal, orange, green, lightGray } = dataColors;


const TopSellingReport = () => {
    const isLargeScreen = useAtBreakpoint('xl');
    const {filter, error, stateStatus } =useSelector(selectTopFiveState)
    const country = useSelector((state: AppState) => state.login.user.countryCode)
    const productData = useSelector(selectTopFiveState)
    const dispatch = useDispatch()

    React.useEffect(()=> {
        dispatch(getTopFiveReport(filter))
    },[dispatch,filter])

    const itemCount = productData.data.products.map(i => i.qty);
    const itemPrices = productData.data.products.map(j => j.price);
    //const chartValues = productData.data.products.sort((a,b)=> b.percentage - a.percentage).map(i => i.percentage ).slice(0,5)
    const chartValues = productData.data.products.sort((a,b)=> (b.price * b.qty) - (a.price * a.qty)).map(i => (i.price * i.qty) ).slice(0,5)
    const maxProfit =  productData.data.products.reduce((a,product)=> a+product.price * product.qty,0).toFixed(2)
    const CurrencySign = () => country === 'PH' ? <span>₱ {maxProfit}</span> : <span>S$ {maxProfit}</span>
    const SaleBox = () => {
        return <Box display='flex' mb={1} justifyContent="flex-start">
            <Box>
                {/* <Typography style={{ color: '#B57937' }}>${' '}{totalSalesValue.toFixed(2)}</Typography> */}
                <Typography style={{ color: '#B57937' }}><CurrencySign /></Typography>
            </Box>
        </Box>
    }
    const manageTop5ChartOptions = (
        data: number[],
        prices:number[]
    ): ChartOptions<'bar'> => ({
        responsive: true,
        datasets: {
            bar: {
                barThickness: 12,
                borderRadius(ctx, options) {
                    return {
                        topLeft: 6,
                        topRight: 6,
                        bottomLeft: 0,
                        bottomRight: 0,
                    };
                },
            },
        },
        plugins: {
            tooltip:{
                filter: (tooltipItem) => tooltipItem.datasetIndex === 0
            },
            legend: {
                display: false,
                position: "bottom"
            },
        },
        scales: {
            y: {
                max:Number(maxProfit),
                ticks: {
                    font: {
                        size: 14,
                    },
                    color: dataColors.textColor,
                    count:5,
                    
                },
                stacked:true
            },
            x: {
                ticks: {
                    font: {
                        size: 10,
                    },
                    color: dataColors.textColor,
                },
            },
            'total-x-axis': {
                position: 'top',
                ticks: {
                    callback(tickValue, index, ticks) {
                        const y = data[index] * prices[index]
                        return y
                    },
                },
            },
        },
    });
    console.log(productData.data.products)
    const dataToDisplay: ChartData = {
       labels: productData.data.products.map(i => i.name),
        datasets: [
            {
                label: 'Product Sale',
                data: chartValues ,
                backgroundColor: [blue, red, brown, teal, orange],
                
            },
            {
                label: "",
                data: Array(5).fill(maxProfit), // temporary
                backgroundColor: lightGray,
                xAxisID: 'total-x-axis',
                pointHitRadius:0,
                pointRadius:0
                
            },
        ],
    };
    const handleFilterChange = (key: keyof typeof filter, value: string) => {
        dispatch(
            setTopFiveFilter({
                ...filter,
                [key]: value,
            }),
        );
    }
    return (
        <ChartContainer
            title="Top 5 - Selling Products"
            loading={stateStatus === 'loading'}
            error={error}
            noData={productData.data.products.length === 0}
            controls={
                <SingleFilterGroup
                    filter={filter}
                    onFilterChange={handleFilterChange}
                />
            }>
            <SaleBox />
            <Bar
                height={isLargeScreen? 80 : 70}
                type="bar"
                options={manageTop5ChartOptions(itemCount,itemPrices)}
                data={dataToDisplay}
            />
        </ChartContainer>
    );
};

export default TopSellingReport;
