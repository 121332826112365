import { ChangeEvent, useState } from "react";
import type { SortColumn } from "./table-types";
import _ from "lodash";

// Table Utils Hooks
interface ITableUtilsOptions {
  initialSortBy?: string;
  initialPage?: number;
  initialRowsPerPage?: number;
}

export default function useTableUtils(options: ITableUtilsOptions = {}) {
  const hookOptions = {
    initialSortBy: "dateCreated",
    initialPage: 0,
    initialRowsPerPage: 10,
    ...options,
  };
  const { initialSortBy, initialPage, initialRowsPerPage } = hookOptions;

  const [sortColumn, setSortColumn] = useState<SortColumn>({
    path: initialSortBy,
    order: "asc",
  });

  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [search, setSearch] = useState("");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const resetToPageOne = () => setPage(0);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // sort handler
  const sortHandler = (sortCol: SortColumn) => {
    if (!sortCol) return;
    setSortColumn({ ...sortCol });
  };

  // sort function
  const sort = (items: any[], path: string, order: "asc" | "desc") =>
    _.orderBy(items, [path], [order]);

  // for table pagination
  const paginate = (items: any[], currentPage: number, pageSize: number) => {
    const startIndex = currentPage * pageSize;
    return _(items).slice(startIndex).take(pageSize).value();
  };

  // for search
  // NEEDS A CHANGE
  const filterBySearch = (data: any[], query?: string) => {
    const term = query ? query : search;

    if (!term) return data;

    return _.filter(data, (row: any) => {
      // const id = row.id ?? '';
      return (
        JSON.stringify(_.omit(row, "id")).toLowerCase().indexOf(term.trim().toLowerCase()) > -1
      );
    });
  };

  return {
    sortColumn,
    sortHandler,
    sort,
    paginate,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    resetToPageOne,
    search,
    setSearch,
    filterBySearch,
    setPage,
  };
}
