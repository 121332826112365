export type Status = 'idle' | 'loading' | 'success' | 'error';

// export interface MarketReportItem<T> {
//     id: string;
//     status: T;
// }

export interface MarketReportItem {
    total:number,
    products:Item[]
}

export interface Item {
    name:string;
    qty:number;
    percentage:number;
    price:number;
    discount:number;
}

export interface TopFiveReportState<S> {
    stateStatus: Status;
    error?: string | null;
    data: MarketReportItem;
    filter: {
        month:string;
        year: string;
    };
}

export type TopFiveProductReportState = TopFiveReportState<Status>

//Manage Top 5 Products

export const LOAD_TOP_FIVE_PRODUCTS_REPORT = 'top-five/data/load';
export const SET_TOP_FIVE_PRODUCTS_STATUS = 'top-five/status/set';
export const SET_TOP_FIVE_PRODUCTS_FILTER = 'top-five/filter/load';
export const SET_TOP_FIVE_PRODUCTS_ERROR = 'top-five/error/set';

interface LoadTopFiveAction {
    type: typeof LOAD_TOP_FIVE_PRODUCTS_REPORT;
    payload: TopFiveProductReportState['data'];
};

interface SetTopFiveStatusAction {
    type: typeof SET_TOP_FIVE_PRODUCTS_STATUS;
    payload: TopFiveProductReportState['stateStatus'];
};

interface SetTopFiveFilterAction {
    type: typeof SET_TOP_FIVE_PRODUCTS_FILTER;
    payload: TopFiveProductReportState['filter'];
};

interface SetTopFiveErrorAction {
    type: typeof SET_TOP_FIVE_PRODUCTS_ERROR;
    payload: TopFiveProductReportState['error'];
};

export type TotalFiveProductAction = 
    | LoadTopFiveAction
    | SetTopFiveStatusAction
    | SetTopFiveFilterAction
    | SetTopFiveErrorAction;