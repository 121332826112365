import React from 'react';
import ReportContainer from './ReportContainer';
import TopSellingReport from './TopSellingReport';
import TotalSalesReport from './TotalSalesReport';

const SummaryReportView = () => {
    return <ReportContainer  style={{
        display: 'flex',
        flexDirection:'column',
    }}>
        <TotalSalesReport />
        <TopSellingReport />
    </ReportContainer>;
};

export default SummaryReportView;
